import React from "react"
import P from "../p"
import QuestionHover from "../questionhover"

const UnitSubSections = props => (
  <div className={`flex flex-col`}>
    {props.sections.map((section, i) => {
      return (
        <div key={i} className="w-full flex flex-col">
          <div className={`flex flex-row content-center items-center`}>
            <h4 className="w-auto">{section.name}</h4>
            <div>
              {section.help_text && (
                <QuestionHover
                  help_text={section.help_text}
                  subject={props.subject.toLowerCase()}
                />
              )}
            </div>
          </div>
          <div className="w-full">
            <P content={section.content} />
          </div>
        </div>
      )
    })}
  </div>
)

export default UnitSubSections
