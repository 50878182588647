import React from "react"

const QuestionHover = props => (
  <div className={`ml-2 mb-2 h-full flex flex-col ${props.subject}`}>
    <div
      className="rounded-full h-5 w-5 flex items-center justify-center question-hover cursor-pointer"
      aria-label={props.help_text}
      data-balloon-pos="right"
      data-balloon-length="large"
    >
      <p className="text-sm leading-tight">?</p>
    </div>
  </div>
)

export default QuestionHover
