import React from "react"

import { Link } from "gatsby"

import P from "../p"

const LessonMap = props => (
  <div className={`${props.subject} flex flex-col w-full`}>
    {props.lessons.map((lesson, i) => {
      return (
        <Link key={i} to={lesson.url} className="cursor-pointer">
          <div
            className={`w-full flex flex-row my-2 shadow-lg hover:bg-${props.subject}-pale `}
          >
            <div className="w-1/5 text-center p-4 lesson-num-box">
              <h1 className="lesson-num">{lesson.num}</h1>
            </div>
            <div className="w-1/5"></div>
            <div className="w-3/5 m-2">
              <P content={lesson.objective} />
            </div>
          </div>
        </Link>
      )
    })}
  </div>
)

export default LessonMap
