import React from "react"
import groupBy from "lodash/groupBy"

function Vocab(props) {
  const groupVocab = () => {
    return groupBy(props.vocab, "category")
  }

  return (
    <div>
      {props.vocab_type === "table" ? (
        <div className="vocab-table">
          {props.vocab.map((word, i) => {
            return (
              <div
                key={i}
                className="border border-grey-400 w-1/3 flex flex-row p-2"
              >
                <div className="flex-1 self-center">
                  <p key={i} className="table-word">
                    {word.word}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <>
          {Object.keys(groupVocab()).length >= 1 ? (
            Object.keys(groupVocab()).map((key, index) => (
              <div key={index}>
                {key !== "null" ? <h4>{key}</h4> : ""}
                <div className="pl-12 flex flex-wrap">
                  {groupVocab()[key].map(word => (
                    <p className="comma">{word.word}</p>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <React.Fragment>
              {props.vocab.map(word => (
                <div key={word.id}>
                  <p>{word.word}</p>
                </div>
              ))}
            </React.Fragment>
          )}
        </>
      )}
    </div>
  )
}

export default Vocab
