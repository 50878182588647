import React from "react"
import { Link } from "gatsby"
import groupBy from "lodash/groupBy"
import orderBy from "lodash/orderBy"

function Core(props) {
  const groupStandards = () => {
    const standards = groupBy(
      orderBy(props.standards, "readonly_parent", "asc"),
      "readonly_parent"
    )
    return standards
  }
  return (
    <div>
      {Object.keys(groupStandards()).map((key, index) => (
        <div key={index}>
          {key !== "null" ? <h4>{key}</h4> : ""}
          <ul className={props.subject}>
            {groupStandards()[key].map(standard => (
              <li>
                <p className="my-0">
                  <Link
                    to={`/search/?Standards=%5B"${standard.short_code}"%5D`}
                  >
                    {standard.short_code}
                  </Link>{" "}
                  &mdash; {standard.description}
                </p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

export default Core
