import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import P from "../components/p"
import CoreStandards from "../components/Standards/core"
import OtherStandards from "../components/Standards/other"
import QuestionHover from "../components/questionhover"
import Staff from "../components/staff"
import Vocab from "../components/Vocab"
import UnitSubSections from "../components/UnitSubsections"
import LessonMap from "../components/LessonMap"

const Unit = ({ pageContext: { unit } }) => (
  <>
    <Layout>
      <Staff link={unit.staff_url} />
      <SEO title={unit.computed_seo_title} />
      <Hero
        subject={unit.subject_name.toLowerCase()}
        title={unit.full_name}
        subTitle={unit.sub_title}
        description={unit.short_description}
        crumbs={unit.crumbs}
        download_url={unit.url}
        download_title={"Full Unit"}
      />
      <div
        className={`container mx-auto py-8 ${unit.subject_name.toLowerCase()} unit flex flex-row`}
      >
        <hr></hr>
        <div className="w-full md:w-1/5">Map</div>
        <div className="w-full md:w-4/5 flex flex-col">
          {/* Unit Summary Start */}
          <div className="flex flex-col">
            <h2 className="uppercase">Unit Summary</h2>
            <P content={unit.why} />
          </div>
          {/* Unit Summary End */}

          {/* Assessment Start */}
          <div className="flex flex-col py-4">
            <h2 className="uppercase">Assessment</h2>
            <p>
              This assessment accompanies Unit {unit.order} and should be given
              on the suggested assessment day or after completing the unit.
            </p>
          </div>
          {/* Assessment End */}

          {/* Unit Prep Start  */}
          <div className="flex flex-col py-4">
            <h2 className="uppercase">Unit Prep</h2>
            {unit.content_sections && (
              <>
                {Object.keys(unit.content_sections).map((obj, i) => {
                  return (
                    <div
                      className={`flex flex-col ${unit.subject_name.toLowerCase()}`}
                    >
                      <div
                        key={i}
                        className={`flex flex-row ${unit.subject_name.toLowerCase()} content-center items-center`}
                      >
                        <h3 className="unit-prep-name w-auto">
                          {unit.content_sections[obj].name}
                        </h3>
                        {unit.content_sections[obj].help_text && (
                          <QuestionHover
                            help_text={unit.content_sections[obj].help_text}
                            subject={unit.subject_name.toLowerCase()}
                          />
                        )}
                      </div>
                      <div>
                        <P content={unit.content_sections[obj].content} />
                      </div>
                      <div>
                        {unit.content_sections[obj].vocab_section &&
                          unit.content_sections[obj].vocab_section.length >
                            0 && (
                            <Vocab
                              vocab={unit.content_sections[obj].vocab_section}
                              vocab_type={
                                unit.subject_name === "MATH"
                                  ? "table"
                                  : "category"
                              }
                            />
                          )}
                      </div>
                      <div>
                        {unit.content_sections[obj].subsections &&
                          unit.content_sections[obj].subsections.length > 0 && (
                            <UnitSubSections
                              sections={unit.content_sections[obj].subsections}
                              subject={unit.subject_name.toLowerCase()}
                            />
                          )}
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          </div>
          {/* Unit Prep End  */}

          {/* Lesson Map Start  */}
          <div className="flex flex-col py-4">
            <h2 className="uppercase">Lesson Map</h2>
            <div>
              {unit.lessons && unit.lessons.length > 0 && (
                <LessonMap
                  subject={unit.subject_name.toLowerCase()}
                  lessons={unit.lessons}
                />
              )}
            </div>
          </div>
          {/* Lesson Map End  */}

          {/* Standards Start  */}
          <div className="flex flex-col py-4">
            <h2 className="uppercase">Common Core Standards</h2>
            {unit.core && unit.core.length > 0 && (
              <div className="flex flex-col">
                <div
                  className={`flex flex-row ${unit.subject_name.toLowerCase()} content-center items-center`}
                >
                  <h3 className="unit-prep-name w-auto">Content Standards</h3>
                  <QuestionHover
                    help_text={`The content standards covered in this unit`}
                    subject={unit.subject_name.toLowerCase()}
                  />
                </div>
                <CoreStandards
                  standards={unit.core}
                  subject={unit.subject_name.toLowerCase()}
                />
              </div>
            )}
            {unit.foundational && unit.foundational.length > 0 && (
              <div className="flex flex-col">
                <div
                  className={`flex flex-row ${unit.subject_name.toLowerCase()} content-center items-center`}
                >
                  <h3 className="unit-prep-name w-auto">
                    Foundational Standards
                  </h3>
                  <QuestionHover
                    help_text={`The content standards covered in this unit`}
                    subject={unit.subject_name.toLowerCase()}
                  />
                </div>
                <OtherStandards
                  standards={unit.foundational}
                  subject={unit.subject_name.toLowerCase()}
                />
              </div>
            )}
            {unit.future && unit.future.length > 0 && (
              <div className="flex flex-col">
                <div
                  className={`flex flex-row ${unit.subject_name.toLowerCase()} content-center items-center`}
                >
                  <h3 className="unit-prep-name w-auto">Future Standards</h3>
                  <QuestionHover
                    help_text={`The content standards covered in this unit`}
                    subject={unit.subject_name.toLowerCase()}
                  />
                </div>
                <OtherStandards
                  standards={unit.future}
                  subject={unit.subject_name.toLowerCase()}
                />
              </div>
            )}
          </div>
          {/* Standards End  */}
        </div>
      </div>
      <div className="container mx-auto">
        {(unit.prev_slug || unit.next_slug) && (
          <div className="flex flex-wrap items-center">
            <div className={`w-full md:w-1/2 flex flex-row justify-start`}>
              {unit.prev_slug && (
                <Link
                  to={unit.prev_slug}
                  className={`w-full p-8 bg-${unit.subject_name.toLowerCase()} text-white`}
                >
                  <div>
                    <p>Previous</p>
                    <div>
                      <h2 className="fancy-underline text-white">
                        Unit {unit.prev_num}
                      </h2>
                    </div>
                    <h3 className="text-white">{unit.prev_name}</h3>
                  </div>
                </Link>
              )}
            </div>
            <div
              className={`w-full md:w-1/2 flex flex-row justify-end text-right`}
            >
              {unit.next_slug && (
                <div
                  className={`w-full bg-${unit.subject_name.toLowerCase()}-pale flex flex-col justify-end text-right text-white`}
                >
                  <Link
                    to={unit.next_slug}
                    className={`w-full p-8 bg-${unit.subject_name.toLowerCase()}-pale text-white`}
                  >
                    <p>Next</p>
                    <div className="text-right justify-end">
                      <h2 className="fancy-underline text-white">
                        Unit {unit.next_num}
                      </h2>
                    </div>
                    <h3 className="text-white">{unit.next_name}</h3>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  </>
)

export default Unit
